import React from "react";

import { theme } from "@/util/theme";

import { Particles } from "@/components/particles";

import "./layout.scss";

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const themeName = theme();

  return (
    <div className={`page page--theme-${themeName}`}>
      {children}
      <Particles />
    </div>
  );
};
