import FlipClock from "@millmen/react-flip-clock";
import { useEffect, useState } from "react";

import { CountdownApiData, fetchApiData } from "@/util/fetchCountdownApiData";

import { Column, Container, Row } from "@/components/grid";
import { HeartShapeImage } from "@/components/image";
import { Slider } from "@/components/slider";

import "./countdown.scss";

const Countdown = () => {
  const [countdownApiData, setCountdownApiData] = useState<CountdownApiData>();

  useEffect(() => {
    fetchApiData()
      .then((data) => setCountdownApiData(data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container extraClassName="h-100 countdown">
      <Row extraClassName="h-100">
        <Column xs={12} md={6} extraClassName="countdown__slideshow">
          <h1 className="countdown__slideshow-title">Robin + Julia 💕</h1>
          <Slider className="countdown__slideshow-slider">
            {countdownApiData?.images.map((image, index) => (
              <HeartShapeImage key={index} image={image} />
            ))}
          </Slider>
        </Column>
        <Column xs={12} md={6} extraClassName="countdown__counter">
          <h2 className="countdown__counter-title">
            Counting down until we are together again 💖
          </h2>
          <FlipClock
            countDownDate={
              countdownApiData?.date
                ? new Date(countdownApiData.date)
                : new Date()
            }
          />
        </Column>
      </Row>
    </Container>
  );
};

export default Countdown;
