import dayjs from "dayjs";
import "dayjs/locale/nl";
import isLeapYear from "dayjs/plugin/isLeapYear";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { Layout } from "@/components/layout";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Countdown from "./routes/countdown/countdown";

dayjs.extend(isLeapYear); // use plugin
dayjs.locale("nl"); // use locale

const router = createBrowserRouter([
  {
    path: "/",
    element: <Countdown />
  }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Layout>
      <RouterProvider router={router} />
    </Layout>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
