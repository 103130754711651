import { CountdownImage } from "@/util/fetchCountdownApiData";

export interface ImageProps {
  image: CountdownImage;
}

export const Image = ({ image }: ImageProps) => (
  <img
    src={image.url}
    style={{
      objectPosition: `${image.imagePosition.x} ${image.imagePosition.y}`
    }}
  />
);
