import { useEffect, useState } from "react";

enum Breakpoints {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
  XL = "xl"
}

const BreakpointSizes = {
  [Breakpoints.XS]: 0,
  [Breakpoints.SM]: 576,
  [Breakpoints.MD]: 768,
  [Breakpoints.LG]: 992,
  [Breakpoints.XL]: 1200
};

const getBreakpoint = (windowWidth: number): Breakpoints => {
  // Filter on all the sizes that are the same or smaller than the windowWidth,
  // and return the greatest one
  const activeSize = Object.entries(BreakpointSizes)
    .filter(([, value]) => value <= windowWidth)
    .reduce(
      (carry, value) => {
        if (value[1] > carry[1]) {
          carry = value;
        }
        return carry;
      },
      [Breakpoints.XS, BreakpointSizes[Breakpoints.XS]]
    );
  return activeSize[0] as Breakpoints;
};

const useBreakpoint = (): Breakpoints | undefined => {
  const [breakpoint, setBreakpoint] = useState<Breakpoints | undefined>(
    typeof window !== "undefined" ? getBreakpoint(window.innerWidth) : undefined
  );

  useEffect(() => {
    const resizeListener = () => {
      setBreakpoint(getBreakpoint(window.innerWidth));
    };

    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return breakpoint;
};

export { useBreakpoint, Breakpoints };
