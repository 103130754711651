import "./row.scss";

interface RowProps {
  children: React.ReactNode;
  extraClassName?: string;
}

export const Row = ({
  children,
  extraClassName
}: RowProps): React.ReactElement => {
  return (
    <div className={`row${extraClassName ? ` ${extraClassName}` : ""}`}>
      {children}
    </div>
  );
};
