import "./column.scss";

type ColumnSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ColumnProps {
  xs?: ColumnSizes;
  sm?: ColumnSizes;
  md?: ColumnSizes;
  lg?: ColumnSizes;
  children: React.ReactNode;
  extraClassName?: string;
}

export const Column = ({
  xs,
  sm,
  md,
  lg,
  children,
  extraClassName
}: ColumnProps): React.ReactElement => {
  let className = "col";

  if (xs) {
    className += ` col-xs-${xs}`;
  }
  if (sm) {
    className += ` col-sm-${sm}`;
  }
  if (md) {
    className += ` col-md-${md}`;
  }
  if (lg) {
    className += ` col-lg-${lg}`;
  }
  if (extraClassName) {
    className += ` ${extraClassName}`;
  }

  return <div className={className}>{children}</div>;
};
