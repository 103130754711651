import { useEffect, useState } from "react";

import { Breakpoints, useBreakpoint } from "@/hooks/useBreakpoint";

enum DeviceGroups {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop"
}

const BreakpointGroups = {
  [DeviceGroups.MOBILE]: [Breakpoints.XS, Breakpoints.SM],
  [DeviceGroups.TABLET]: [Breakpoints.MD],
  [DeviceGroups.DESKTOP]: [Breakpoints.LG, Breakpoints.XL]
};

const getDeviceGroup = (breakpoint: Breakpoints): DeviceGroups => {
  const deviceGroup = Object.entries(BreakpointGroups).find(([, breakpoints]) =>
    breakpoints.includes(breakpoint)
  );
  return deviceGroup ? (deviceGroup[0] as DeviceGroups) : DeviceGroups.MOBILE;
};

const useDeviceGroup = (): DeviceGroups | undefined => {
  const [deviceGroup, setDeviceGroup] = useState<DeviceGroups | undefined>();
  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (!breakpoint) {
      return;
    }

    // Only update state if needed
    const newDeviceGroup = getDeviceGroup(breakpoint);
    if (deviceGroup !== newDeviceGroup) {
      setDeviceGroup(newDeviceGroup);
    }
  }, [breakpoint]);

  return deviceGroup;
};

export { useDeviceGroup, DeviceGroups };
