import dayjs from "dayjs";

export enum Themes {
  WINTER = "winter",
  DEFAULT = "default"
}
// Zero-indexed months of january, november and december
const winterMonths: number[] = [0, 10, 11];

export const theme = (): Themes => {
  const now = dayjs();
  if (winterMonths.includes(now.month())) {
    return Themes.WINTER;
  }
  return Themes.DEFAULT;
};
