import React, { useMemo } from "react";
import { Snowfall } from "react-snowfall";
import type { SnowfallProps } from "react-snowfall/src/Snowfall";

import { DeviceGroups, useDeviceGroup } from "@/hooks/useDeviceGroup";

import { Themes, theme } from "@/util/theme";

export const Particles = () => {
  const snowfallImage = document.createElement("img");
  const deviceGroup = useDeviceGroup();
  const currentTheme = theme();

  const snowfallConfig = useMemo((): SnowfallProps => {
    switch (theme()) {
      case Themes.WINTER:
        return {
          color: "#fff",
          radius:
            deviceGroup && deviceGroup !== DeviceGroups.MOBILE
              ? [3.0, 12.0]
              : undefined
        };
      case Themes.DEFAULT:
        snowfallImage.src = "/images/pink-heart-emoji.png";
        return {
          images: [snowfallImage],
          radius: [12.0, 20.0],
          snowflakeCount: 100
        };
    }
  }, [currentTheme, deviceGroup]);

  return <Snowfall {...snowfallConfig} />;
};
