import { Image, ImageProps } from "@/components/image/image";

import "./heartShapeImage.scss";

export const HeartShapeImage = ({ image }: ImageProps) => {
  return (
    <div className="image image--heart-shape">
      <Image image={image} />
    </div>
  );
};
