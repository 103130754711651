import "./container.scss";

interface ContainerProps {
  children: React.ReactNode;
  extraClassName?: string;
}

export const Container = ({
  children,
  extraClassName
}: ContainerProps): React.ReactElement => {
  return (
    <div className={`container${extraClassName ? ` ${extraClassName}` : ""}`}>
      {children}
    </div>
  );
};
