import { default as SlickSlider } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

interface SliderProps {
  children: React.ReactNode;
  className?: string;
}
export const Slider = ({ children, className = "" }: SliderProps) => {
  const settings = {
    className: className,
    dots: false,
    fade: true,
    infinite: true,
    centerMode: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    nextArrow: <></>,
    prevArrow: <></>
  };

  return <SlickSlider {...settings}>{children}</SlickSlider>;
};
